@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'iiBulo';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKA iiBulo Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo';
    font-style: italic;
    font-weight: 400;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKA iiBulo Italic.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKA iiBulo Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo';
    font-style: italic;
    font-weight: 700;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKA iiBulo BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo-Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKAiiBuloMono-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo-Mono';
    font-style: italic;
    font-weight: 400;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKAiiBuloMono-Italic.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo-Mono';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKAiiBuloMono-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'iiBulo-Mono';
    font-style: italic;
    font-weight: 700;
    src: url('../public/fonts/KUKA\ iiBULO_Family_2022/KUKAiiBuloMono-BoldItalic.woff2') format('woff2');
}

@layer components{
    .bg-brand-complex-gradient {
        background-color: hsla(0,0%,81%,1);
        background-image:
            radial-gradient(at 42% 75%, hsla(282,0%,100%,1) 0px, transparent 50%),
            radial-gradient(at 93% 93%, hsla(71,0%,100%,1) 0px, transparent 50%),
            radial-gradient(at 95% 44%, hsla(244,0%,100%,1) 0px, transparent 50%),
            radial-gradient(at 96% 35%, hsla(0,0%,91%,1) 0px, transparent 50%),
            radial-gradient(at 10% 36%, hsla(0,100%,99%,1) 0px, transparent 50%),
            radial-gradient(at 71% 93%, hsla(20,100%,50%,0.68) 0px, transparent 50%),
            radial-gradient(at 57% 15%, hsla(286,15%,96%,1) 0px, transparent 50%);
    }
    
    .dark .bg-brand-complex-gradient {
        background-color: #171717;
        background-image: none;

        background: radial-gradient(at 57.78665883044373% 58.32911605937922%, #171717 0px, transparent 50%), radial-gradient(at 54% 0%, #262626 0px, transparent 50%), radial-gradient(at 21.602115780193948% 67.64085695006747%, #262626 0px, transparent 50%), radial-gradient(at 97.95827211284161% 66.29132928475033%, #262626 0px, transparent 50%) #0A0A0A;
        mix-blend-mode: var(--_mesh-gradient-blend-mode);
        filter: blur(var(--_mesh-gradient-blur));
    }

    .mask-gradient-bottom {
        -webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
        mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    }

}
    
code {
    overflow-x: auto;
    word-wrap: break-word;
    white-space: pre-wrap;
}

body {
    font-family: 'iiBulo', sans-serif;
}